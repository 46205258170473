.cbp_tmtimeline,
.cbp_tmtimeline li {
  position: relative;
}
.date,
.socrates {
  font-weight: 500;
}
.cbp_tmtimeline > li .cbp_tmlabel,
.tobias {
  background: var(--secondary);
  border-radius: 5px;
  min-height: 150px;
}
body {
  -webkit-font-smoothing: antialiased;
}
.sectionClass {
  display: block;
}
.row {
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cbp_tmtimeline {
  max-width: 900px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 10px;
  color: #fff;
}
.cbp_tmtimeline > li .cbp_tmlabel {
  margin: 0 0 0 8px;
  padding: 25px;
}
.cbp_tmtimeline li .cbp_tmlabel h4,
.tobias h4 {
  opacity: 0.6;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  line-height: 1.2em;
  font-weight: 600;
  padding: 5px 0 10px;
}
.cbp_tmtimeline > li .cbp_tmlabel h4 i,
.tobias i {
  margin-right: 10px;
  vertical-align: middle;
}
.date {
  opacity: 0.6;
  font-size: 14px;
  padding-top: 5px;
  top: 25px;
  right: 20px;
}
.date i {
  margin-right: 10px;
  font-size: 15px;
  line-height: 20px;
}
@media screen and (min-width: 700px) {
  .date {
    position: absolute;
    padding: 0;
  }
}
.socrates {
  text-decoration: underline;
}
.tobias {
  animation-name: fadeInRight;
  margin: 0 12px 15px 15px;
  color: var(--text);
  padding: 25px;
  visibility: visible;
  max-width: 885px;
  position: relative;
  color: #fff;
}
.mycarreer ul {
  border-left: 8px solid var(--secondary);
}
.mycarreer {
  margin-top: 50px;
}
