:root {
  --text-dark: white;
  --background-dark: #000d2a;
  --primary-dark: #8fb4ff;
  --secondary-dark: #033f3f;
  --accent-dark: #8fb4ff;

  --text-light: black;
  --background-light: #eaeaea;
  --primary-light: #3498db;
  --secondary-light: #033f3f;
  --accent-light: #3498db;
}

body[data-theme="dark"] {
  --text: var(--text-dark);
  --background: var(--background-dark);
  --primary: var(--primary-dark);
  --secondary: var(--secondary-dark);
  --accent: var(--accent-dark);
}

body[data-theme="light"] {
  --text: var(--text-light);
  --background: var(--background-light);
  --primary: var(--primary-light);
  --secondary: var(--secondary-light);
  --accent: var(--accent-light);
}

body[data-reduce-motion="true"] .animated {
  animation: none !important;
  transition: none !important;
}
