.button.result:hover,
.card {
  color: #fff;
}
.projects-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  padding: 0 20px;
  min-height: 300px;
}
.card {
  width: 300px;
  background-color: var(--secondary);
  border-radius: 10px;
  box-shadow: 0 1px 10px 1px #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  gap: 15px;
  transition: 0.5s;
  text-align: center;
  max-height: 550px;
}
.card:hover {
  transform: scale(1.02);
  cursor: pointer;
  box-shadow: 0 0 10px 0 var(--primary);
}
.card img {
  object-fit: cover;
  width: 270px;
  height: 270px;
  border-radius: 20px;
}
#spark,
.nuspi img,
.spark img {
  object-fit: contain;
}
.card hr {
  width: 95%;
  opacity: 0.5;
}
.card .project-skills {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 25px;
}
.card .project-skills img {
  width: 40px;
  height: 40px;
  border-radius: 0;
  object-fit: contain;
}
.card p {
  font-family: "Hind Madurai", sans-serif;
  font-weight: 00px;
  opacity: 0.8;
}
.card.expanded {
  width: 500px;
  height: 100%;
  max-height: 1000px;
  transition: 0.4s;
  padding: 35px 35px 25px;
  overflow: hidden;
}
.card.expanded .front-card,
.card.expanded .project-skills,
.project-details,
label input {
  display: none;
}
.card.expanded .project-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  width: 100%;
  height: 100%;
  gap: 10px;
  position: relative;
}
.card.expanded:hover {
  transform: scale(1);
}
.front-card {
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.project-details .close {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  top: -15px;
  right: -15px;
}
.button.result {
  width: fit-content;
  text-align: center;
  font-size: 1rem;
  padding: 5px 10px;
  margin-top: 10px;
  font-weight: 600;
  justify-self: flex-start;
  display: flex;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border: none;
  gap: 16px;
}
.close-button {
  background: var(--primary);
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
}
.skill-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.tooltip,
.tooltip-text {
  visibility: hidden;
  width: 200px;
  text-wrap: wrap;
  background-color: var(--secondary);
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: 90px;
  left: 50%;
  margin-left: -60%;
  opacity: 0;
  transition: opacity 0.5s;
  font-weight: 500;
}
.filter-icon,
.tooltip-text {
  background-color: var(--primary);
}
.tooltip-text {
  padding: 10px;
  width: auto;
  top: 55px;
}
.skill-card:hover .tooltip,
.skill-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.cardtekst {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-height: 95px;
}
.filters-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 11px;
}
.filters-container .button {
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
}
.filter-icon {
  border-radius: 5px;
  padding: 5px !important;
  font-size: 27px;
  color: #000;
  display: flex;
}
.filters {
  display: flex;
  gap: 20px;
}
.reset {
  font-size: 18px;
  padding: 5px 10px;
}
.no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
