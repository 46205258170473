@import url(https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600&family=Montserrat:wght@300;400;500;700;800&family=Open+Sans:wght@300;400;500;600&display=swap);
.animate-switcher,
.mode-switcher__box {
  border: 2px solid #000;
  display: flex;
  cursor: pointer;
}
.switcher-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.mode-switcher__box {
  width: 200px;
}
.animate-switcher__half,
.mode-switcher__half {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mode-switcher__half--dark {
  background-color: #a9a9a9;
  color: #fff;
}
.mode-switcher__half--light {
  background-color: #d3d3d3;
  color: #000;
}
.animate-switcher__half--active,
.mode-switcher__half--active {
  background-color: #00f;
  color: #fff;
}
.animate-switcher {
  width: 100px;
}
.settings-content,
.settings-menu,
.theme .toggle.checked span {
  background-color: var(--accent);
}
.settings-button.active,
.settings-menu {
  color: var(--background);
}
.settings-menu {
  position: fixed;
  top: 88px;
  left: 28px;
  z-index: 1000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.gear {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}
.settings .settings-button {
  cursor: pointer;
  font-size: 20px;
}
.settings-content {
  display: none;
  flex-direction: column;
  border: none;
  padding: 10px;
  margin: 10px;
  color: #000;
  width: 200px;
}
.settings-content button {
  transition: none;
  background-color: var(--background) !important;
}
.toggle.checked:before,
body[data-theme="dark"] .settings-content button {
  color: #fff;
}
.settings-content.active {
  display: flex;
  gap: 15px;
}
.left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.gear.active {
  display: none;
}
.left h4 {
  font-size: 20px;
  font-family: Montserrat, sans-serif;
}
.dn {
  position: absolute;
}
.toggle {
  width: 100%;
  background: #d21626;
}
.toggle.checked {
  background: #66b317;
}
.toggle__handler {
  display: inline-block;
  z-index: 1;
  background: #fff;
  transform: translateX(0);
}
.theme .toggle:before {
  content: "Light";
}
.theme .toggle:after {
  content: "Dark";
  right: 15px;
  color: #000;
}
.toggleWrapper {
  position: relative;
  width: 120px;
  height: 50px;
}
.animate-checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.toggle {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background-color: #d21626;
  border-radius: 5px;
  transition: background-color 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.theme .toggle,
.toggle__handler {
  background-color: #fff;
}
.theme .toggle span {
  background-color: #000;
}
.theme .toggle.checked {
  background-color: var(--background);
}
.toggle.checked {
  background-color: #66b317;
}
.theme .toggle.checked:after {
  color: #000;
}
.theme .toggle:before {
  color: #fff;
  left: 14px;
}
.toggle__handler {
  position: relative;
  width: 54px;
  height: 35px;
  border-radius: 3px;
  top: 2.5px;
  left: 3px;
  transition: transform 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.toggle.checked .toggle__handler {
  transform: translateX(60px);
}
.toggle:after,
.toggle:before {
  position: absolute;
  line-height: 40px;
  font-size: 14px;
  z-index: 2;
  transition: color 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.toggle:before {
  content: "OFF";
  left: 20px;
  color: #d21626;
}
.toggle:after {
  content: "ON";
  right: 20px;
  color: #fff;
}
.toggle.checked:after {
  color: #66b317;
}
