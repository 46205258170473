@media only screen and (min-width: 475px) {
  .contact {
    margin: 0 50px !important;
  }
}
@media only screen and (max-width: 767px) {
  .card.expanded {
    justify-content: start;
    padding: 20px;
  }
  .project-details .close-button {
    margin-top: 10px;
    margin-right: 10px;
  }
  a.button.result {
    width: 100%;
    max-width: 200px;
  }
  .skills {
    padding: 0 80px;
  }
  .tooltip {
    top: 90px;
  }
  .settings-menu {
    top: 65px !important;
    left: 10px !important;
  }
  .destroy,
  .nuclear {
    top: 120px;
    left: 10px;
  }
}
