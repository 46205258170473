@import url("https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600&family=Montserrat:wght@300;400;500;700;800&family=Open+Sans:wght@300;400;500;600&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  cursor: var(--cursor);
  text-decoration: none;
  color: var(--tekst);
}

main {
  display: flex;
  flex-direction: column;
  gap: 200px;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: var(--background);
  text-decoration: none;
  cursor: var(--cursor);
  scroll-behavior: smooth;
  color: var(--text);
  height: 5000px;
}
html {
  scroll-behavior: smooth;
}
a:hover,
button:hover {
  cursor: pointer;
}

h3 {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  padding-top: 5px;
}

p {
  font-family: "Hind Madurai", sans-serif;
  font-size: 17px;
}

button {
  border: none;
}

/* Title CTA */
.title {
  display: flex;
  flex-direction: column;
  height: 100vh;

  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: 700;
  color: var(--white-black);
  text-align: center;
  font-family: "Montserrat", sans-serif;
  gap: 20px;
}
.title span {
  color: var(--accent);
}

/* Titles */
.title2 {
  text-align: center;
  color: var(--text);
  font-size: 2.3em;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

#workexperience .title2 {
  margin-top: 0;
}

/* Button */
.button,
.button-selected {
  padding: 10px 15px;
  background-color: var(--accent);
  color: black;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.2s, transform 0.2s;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  transition: all 0.2s ease-in-out;
}
.button:hover,
.button-deselected {
  border-radius: 5px;
  background-color: var(--secundary);
  transform: scale(1.05);
  cursor: pointer;
  border: 1px solid var(--accent);
  color: var(--white-black);
  box-shadow: 0px 0px 2px 1px var(--accent);
}

.button-deselected {
  padding: 10px 15px;
}
.menu-icon {
  cursor: pointer;
}
#projects,
#skills {
  top: 100px;
}

.readmore {
  text-align: center;
  margin-bottom: 20px;
  opacity: 0.6;
}

/* FOOTER */
footer {
  height: 200px;
  background-color: var(--background);
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* Static glitch effect based on the 5% keyframe */
.glitch {
  transform: skewX(4deg);
  text-shadow: 0px -2rem magenta, -5px 5px #fe0000;
}

.glitch-animated {
  animation: glitch 0.4s linear 2;
}
@keyframes glitch {
  0%,
  50%,
  100% {
    transform: skewX(0deg) translateX(0px) scale(1, 1);
    text-shadow: 0px 0 cyan;
    clip-path: none;
  }
  5%,
  15%,
  25% {
    transform: skewX(12deg) translateX(-5px) scale(1, -1);
    text-shadow: 0px -2rem magenta, -5px 5px #fe0000;
  }
  10%,
  30% {
    transform: skewX(25deg) translateX(15px) scale(1, 1);
    text-shadow: -1rem 2rem yellow;
  }
  35%,
  45% {
    transform: skewX(-12deg) translateX(-5px) scale(1, 1);
    text-shadow: -1rem 2rem #fe0000, -10px 0px #ff00fe;
  }
  11%,
  14% {
    clip-path: polygon(
      100% 0,
      0 18%,
      99% 46%,
      0 78%,
      100% 100%,
      100% 57%,
      0 100%,
      100% 76%,
      0 59%,
      100% 35%,
      0 37%,
      100% 21%
    );
  }
  12%,
  16% {
    clip-path: polygon(
      0 51%,
      0 29%,
      100% 0,
      0 14%,
      99% 57%,
      100% 23%,
      0 100%,
      100% 72%,
      0 0,
      100% 100%
    );
  }
  13%,
  17% {
    clip-path: polygon(
      0% 0%,
      99% 0%,
      99% 8%,
      0% 7%,
      0% 14%,
      99% 15%,
      100% 22%,
      0% 22%,
      0% 30%,
      99% 30%,
      99% 39%,
      0% 37%,
      0% 43%,
      100% 45%,
      100% 50%,
      0% 49%,
      0% 56%,
      99% 57%,
      99% 63%,
      0% 63%,
      0% 68%,
      99% 68%,
      99% 75%,
      0% 76%,
      0% 81%,
      100% 83%,
      100% 89%,
      0% 88%,
      0% 94%,
      100% 95%,
      100% 100%,
      0% 99%
    );
  }
}

.destroy {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: fixed;
  top: 150px;
  left: 28px;
  z-index: 999;
  font-weight: 600;
  font-size: 35px;
}

.repair:hover {
  border-color: lime;
  box-shadow: 0 0 8px 1px lime;
  color: lime;
  background-color: var(--background);
}

.glitch-background {
  background-image: url("../src/components/images/glitch.jpeg");
}

.nuclear {
  font-size: 28px;
  background-color: #820309;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 11px;
}

.nuclear.repair {
  background-color: green;
  color: white;
  animation: shadowPulse 2s infinite;
}

@keyframes shadowPulse {
  0% {
    box-shadow: 0 0 5px #00ff00;
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 20px #00ff00, 0 0 30px #00ff00;
    transform: scale(1.2);
  }
  100% {
    box-shadow: 0 0 5px #00ff00;
    transform: scale(1);
  }
}

.lang-icon {
  font-size: 1.5rem;
}
