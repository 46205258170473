.skill,
.skill img,
.skills {
  padding: 10px;
}
#select-skill,
.skills-description h3 {
  color: var(--primary);
  font-size: 1.2em;
}
#select-skill,
.skills-description h3,
li.skill {
  font-weight: 600;
  font-family: Montserrat, sans-serif;
}
.skills {
  color: #fff;
}
.skill-folder-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.folder {
  width: 90%;
  max-width: 800px;
  background: var(--secondary);
  border-radius: 10px;
  border: 1px solid var(--accent);
  box-shadow: 0 0 10px 1px var(--accent);
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin: 0 10vw;
}
.skills-column {
  width: 40%;
  min-width: 180px;
}
.skill img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.skill {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: 0.3s ease-in-out;
}
li.skill {
  border-radius: 4px;
}
li.skill.active,
li.skill:hover {
  background: var(--primary);
  color: var(--secondary);
  cursor: pointer;
  transform: scale(1.05);
}
li.skill.active {
  transform: none;
}
.skills-divider {
  width: 1px;
  background-color: var(--accent);
  box-shadow: 0 0 8px 1px var(--accent);
  margin: 10px 0;
}
.skills-description {
  padding: 30px;
  width: 60%;
  overflow: hidden;
}
.skills-description h3 {
  margin-bottom: 5px;
}
.skills-description span {
  margin-bottom: 20px;
  font-size: 1em;
  color: #fff !important;
}
.skills-column ul {
  padding: 5px 8px 5px 5px;
}
#whatisit {
  padding-bottom: 50px;
}
.skills .readmore {
  color: #000;
}
body[data-theme="dark"] .skills .readmore {
  color: #fff !important;
}
@media screen and (max-width: 800px) {
  .folder {
    margin: 0 20px;
  }
}
@media screen and (max-width: 600px) {
  .folder {
    flex-direction: column;
    align-items: center;
    text-align: left;
  }
  .skills-divider {
    width: 100%;
    height: 1px;
  }
  .skills-column,
  .skills-description {
    width: 100%;
  }
  .skills-column ul {
    padding: 5px;
  }
  .skills-description p {
    margin-bottom: 10px;
  }
  .skills-description {
    padding: 5px 20px 15px;
  }
  #select-skill {
    margin: 0;
  }
  .skill {
    padding: 5px 10px;
  }
  .skill img {
    width: 40px;
    height: 40px;
  }
  #whatisit {
    padding-bottom: 10px;
  }
}
