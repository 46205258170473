.header .logo,
.header li a {
  text-decoration: none;
  display: block;
}
.header .menu-icon,
.header li a {
  color: var(--text);
  position: relative;
}
.logo {
  font-family: Montserrat, sans-serif;
  font-weight: 800;
  color: var(--text);
}
.logo:hover {
  cursor: pointer;
}
.header {
  position: fixed;
  width: 100%;
  z-index: 9999;
  height: auto;
  padding: 0;
  background-color: var(--background);
}
.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}
.header li a {
  padding: 20px;
  font-weight: 400;
  transition: color 0.2s;
  overflow: hidden;
}
.header .menu-btn:hover,
.header li a:hover {
  transition: 0.7s;
  font-weight: 800;
}
.header .logo {
  float: left;
  font-size: 2em;
  padding: 10px 20px;
}
.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  box-shadow: 0 12px 20px 0 #000;
}
.header .menu-icon {
  display: inline-block;
  float: right;
  padding: 28px 20px;
  user-select: none;
}
.header .menu-icon .navicon {
  background: var(--text);
  display: block;
  height: 2px;
  position: relative;
  transition: 0.2s ease-out;
  width: 20px;
}
.header .menu-icon .navicon:after,
.header .menu-icon .navicon:before {
  background: var(--text);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: 0.2s ease-out;
  width: 100%;
}
.header .menu-icon .navicon:before {
  top: 5px;
}
.header .menu-icon .navicon:after {
  top: -5px;
}
.header .menu-btn {
  display: none;
}
.header .menu-btn:checked ~ .menu {
  max-height: 380px;
}
.header .menu-btn:checked ~ .menu-icon .navicon {
  background: 0 0;
}
.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}
.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before {
  top: 0;
}
@media (min-width: 900px) {
  .header li {
    float: left;
  }
  .header li a {
    padding: 20px 30px;
  }
  .header .menu-icon {
    display: none;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
}
.header li a::after,
.header li a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--accent);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s;
}
.header li a::before {
  transform-origin: bottom left;
}
.header li a:hover::after,
.header li a:hover::before {
  transform: scaleX(1);
}
.lang-switch-container select {
  border: 2px solid var(--primary);
  background-color: transparent;
  cursor: pointer;
  padding-right: 7px;
}
@media screen and (max-width: 900px) {
  .lang-switch-container {
    margin-left: 20px;
  }
}
