#socials {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 75px;
}
.socials-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 700px;
  gap: 20px;
  margin: 0 20px;
}
.socials-container hr {
  width: 100%;
  opacity: 0.4;
}
.socials-container i {
  font-size: 45px;
}
.readmore#findme {
  margin-bottom: -50px;
  margin-top: 60px;
}
.addme {
  margin-top: 30px;
}
.socials-container .button {
  padding: 10px 8px;
}
