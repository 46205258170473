.contact form,
.contact form div {
  flex-direction: column;
  display: flex;
}
.contact form {
  justify-content: center;
  gap: 20px;
  max-width: 550px;
  width: 100%;
  transition: 0.5s;
}
.contact {
  margin: 0 20px;
}
.contact form div {
  justify-content: center;
}
.contact form button {
  font-size: 1.2rem;
  padding: 10px 25px;
  border: none;
}
#senddiv,
.contact,
.statusdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact form input,
.contact form textarea {
  margin-top: 5px;
  height: 40px;
  background-color: var(--secondary);
  border-radius: 5px;
  padding: 0 10px;
  font-size: 1rem;
  color: var(--primary);
  border: 2px solid var(--accent);
  font-family: "Hind Madurai", sans-serif;
}
.contact form input:focus,
.contact form textarea:focus {
  outline: 0;
  box-shadow: 0 0 10px 1px var(--accent);
}
.contact form textarea {
  min-height: 120px;
  min-width: 100%;
  padding: 10px;
}
.contact label {
  font-weight: 600;
}
.status {
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 500;
  color: #fff;
  background-color: var(--secondary);
}
.disordered.message label,
.disordered.message textarea,
.disordered.name input,
.disordered.name label {
  transform: skewX(14deg);
  transform: skewy(14deg);
}
.disordered .button,
.disordered.email input,
.disordered.email label {
  transform: skewX(-14deg);
  transform: skewy(-14deg);
}
.disordered.message label,
.disordered.message textarea {
  transform: skewX(14deg);
  transform: skewY(14deg);
}
.disordered.message {
  margin-top: 120px;
}
